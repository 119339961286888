<script setup>
import { ref, watch, reactive } from "vue";
import { useUserStore } from "../stores/user";
import useAuth from "../hooks/useAuth";
import SignoutWarning from "@/components/user/SignoutWarning.vue";

const { signIn, formatPhoneNumber, authLoading } = useAuth();

const userStore = useUserStore();
const tab = ref("email");
const signInParams = reactive({
  email: null,
  phone: null,
  password: null,
});

watch(
  () => signInParams.phone,
  (newValue, _) => {
    signInParams.phone = formatPhoneNumber(newValue);
  }
);
</script>

<template>
  <v-container
    class="d-flex flex-wrap justify-center align-center"
    style="height: 60vh; max-width: 540px"
  >
    <div class="d-flex flex-column justify-center align-center" v-if="authLoading">
      <v-progress-circular
        :width="10"
        :size="100"
        color="teal"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card
      v-if="!userStore.user && !authLoading"
      class="rounded-xl w-100 bg-opacity-50 pa-2"
    >
      <form>
        <v-card-item>
          <v-card-title class="pb-1">Sign in</v-card-title>
        </v-card-item>
        <v-tabs v-model="tab" class="bg-background rounded-shaped">
          <v-tab class="font-weight-bol text-gray-darken-4" disabled>Method</v-tab>
          <v-tab value="email">Email</v-tab>
          <v-tab value="phone">Phone #</v-tab>
        </v-tabs>
        <v-card-text class="mt-4">
          <v-window v-model="tab">
            <v-window-item value="email">
              <div>
                <v-text-field
                  type="email"
                  label="Email"
                  name="email"
                  v-model="signInParams.email"
                />
              </div>
              <div>
                <v-text-field
                  type="password"
                  label="Password"
                  v-model="signInParams.password"
                />
              </div>
            </v-window-item>

            <v-window-item value="phone">
              <div>
                <v-text-field
                  type="tel"
                  v-model="signInParams.phone"
                  maxlength="13"
                  label="Phone Number (10 digit)"
                  prepend-inner-icon="mdi-phone"
                ></v-text-field>
              </div>
              <div>
                <v-text-field
                  type="password"
                  label="Password"
                  v-model="signInParams.password"
                />
              </div>
            </v-window-item>
          </v-window>
          <div class="d-flex justify-space-between mt-2">
            <v-btn variant="text" to="/reset-password">Forgot password?</v-btn>
            <v-btn
              @click.prevent="signIn(signInParams)"
              type="submit"
              color="teal-accent-3"
              >Login</v-btn
            >
          </div>
        </v-card-text>
      </form>
    </v-card>
  </v-container>
</template>
