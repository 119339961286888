<script setup>
import { onMounted } from "vue";
import Login from "../components/Login.vue";
import { setPageTitle } from "../utils/pageTitle";
import { useUserStore } from "../stores/user";
import { useRouter, definePage } from "vue-router/auto";

definePage({
  meta: {
    requiresAuth: false,
  },
});

const userStore = useUserStore();
const router = useRouter();

onMounted(() => {
  setPageTitle("Login");
  if (localStorage.getItem("token") && userStore.user) {
    router.push("/dashboard");
  }
});
</script>

<template>
  <Login />
</template>
